import React from "react"
import styled from "styled-components"
import OperatorsImage from "../images/OperatorsImage"

const Heading3 = styled.h3`
  font-weight: 600;
  font-size: 1.2rem;
  color: black;
  text-align: center;
  margin: 2rem 0.2rem;
`

export const Operators = () => {
  return (
    <>
      <Heading3>Vertailussa mukana</Heading3>
      <OperatorsImage imgName="operaattorit" />
    </>
  )
}
