import React from "react"
import styled from "styled-components"
import { useMokkulaPlans } from "../../hooks/useMokkulaPlans"
import OperatorImage from "../images/OperatorImage"
import { device } from "../../utils/mediaQueries"
import { createRedirectUrl } from "../../utils/redirectHelpers/modifyUrlHelpers"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const Link = styled.a`
  margin: 0rem;
  text-decoration: none;
  @media ${device.tablet} {
    margin: 1rem 0rem;
  }
`

const PlanCard = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  margin: 0rem 0rem;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); */
  border-bottom: 1px solid black;
  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    padding: 0.2rem;
    width: 100%;
  }
  :hover {
    /* background-color: lightgray; */
  }
`

const Operator = styled.div`
  @media ${device.tablet} {
    margin: 1rem 0rem;
  }
`
const Plan = styled.div`
  font-weight: 400;
  font-size: 1rem;
  font-size: 0.9rem;
  max-width: 200px;
  min-width: 200px;
  @media ${device.tablet} {
    margin: 1rem 0rem;
  }
`
const Speed = styled.div`
  font-weight: 800;
  font-weight: 1rem;
`
const Price = styled.div`
  font-weight: 800;
  font-size: 1.5rem;
  @media ${device.tablet} {
    margin: 1rem 0rem;
  }
`
const Button = styled.a`
  text-decoration: none;
  padding: 1rem 2.3rem;
  color: white;
  background-color: #004e91;
  font-size: 1rem;
  max-height: 30px;
  border-radius: 15px;
  :hover {
    background-color: orange;
  }
  @media ${device.tablet} {
    margin: 1rem 0rem;
  }
`
const Desc = styled.div`
  font-size: 0.9rem;
  word-wrap: break-word;
  max-width: 200px;
  @media ${device.tablet} {
    margin: 1rem 0rem;
  }
`

export const MokkulaPlans = props => {
  const place = props.place
  const trackLink = place => {
    typeof window !== "undefined" &&
      window.gtag("event", `${place}: Liittymää klikattu`, {
        event_label: props.name,
      })
  }
  const plans = useMokkulaPlans(props.fourg)
  const plansCards = plans.map((p, i) => {
    return (
      <PlanCard key={i}>
        <Operator>
          <OperatorImage imgName={p.node.img} />
        </Operator>
        <Plan>{p.node.name}</Plan>
        <Speed>{p.node.internetSpeed}Mb/s</Speed>
        <Price>{p.node.price}€/kk</Price>
        <Desc>{p.node.desc}</Desc>
        <Link
          href={p.node.url}
          target="blank"
          rel="nofollow"
          onClick={() => trackLink(place)}
        >
          <Button
            href={createRedirectUrl(props.pageName, p.node.name)}
            target="_blank"
          >
            Tutustu ja tilaa
          </Button>
        </Link>
      </PlanCard>
    )
  })
  return <Container>{plansCards}</Container>
}
