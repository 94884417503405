import React from "react"
import styled from "styled-components"
import Home from "../../assets/house.svg"
import Jardin from "../../assets/botanical.svg"
import Travel from "../../assets/roll-o-plane.svg"
import { device } from "../../utils/mediaQueries"

const Container = styled.div`
  margin: 1rem 10rem;
  @media ${device.tablet} {
    margin: 1rem;
  }
`

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 1rem auto;
  @media ${device.tablet} {
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
  }
`

const Filter = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  border: 1px solid lightgray;
  margin: 1rem;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  width: 200px;

  @media ${device.tablet} {
    width: 300px;
  }
  :hover {
    background-color: #004e91;
    color: white;
  }
`

const Icon = styled.div`
  flex: 1;
  margin: 1rem;
  fill: #004e91;
  ${Filter}:hover & {
    fill: white;
  }
`

const Text = styled.div`
  flex: 1;
  margin: 1rem;
`

const Heading = styled.h3`
  color: black;
  font-weight: 800;
  font-size: 1.8rem;
`

export const FilterComponents = () => {
  return (
    <Container>
      <Heading>Mihin tarkoitukseen tarvitset mokkulaa?</Heading>
      <FilterContainer>
        <Filter href="#matkalle1">
          <Icon>
            <Travel />
          </Icon>
          <Text>Matkalle</Text>
        </Filter>
        <Filter href="#kotiin1">
          <Icon>
            <Home />
          </Icon>
          <Text>Kotiin</Text>
        </Filter>
        <Filter href="#ulos">
          <Icon>
            <Jardin />
          </Icon>
          <Text>Ulos</Text>
        </Filter>
      </FilterContainer>
    </Container>
  )
}
