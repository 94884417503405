import React, { useState } from "react"
import styled from "styled-components"
import MokkulaCard from "../../mokkulaComponents/MokkulaCard"
import { MokkulaPlans } from "../../mokkulaComponents/MokkulaPlans"
import { Operators } from "../Operators"
import { device } from "../../../utils/mediaQueries"

const Container = styled.div`
  min-height: 100vh;
  background-color: #f9f8fd;
  text-align: center;
`

const Heading1 = styled.h1`
  color: black;
  font-weight: 900;
  font-size: 3rem;
  margin: 2rem 0.2rem;
  @media ${device.tablet} {
    font-size: 2rem;
  }
`
const Heading2 = styled.h2`
  font-weight: 200;
  font-size: 2rem;
  color: #515151;
  margin: 2rem 0.2rem;
  padding: 1rem;
  @media ${device.tablet} {
    font-size: 1.5rem;
  }
`

const Button = styled.div`
  background-color: #004e91;
  color: white;
  font-weight: 700;
  padding: 1rem 3rem;
  border-radius: 0.25rem;
  max-width: 300px;
  min-width: 250px;
  margin: 1rem auto;
  cursor: pointer;
  :hover {
    background-color: darkblue;
    transition: 1.5s;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ResultContainer = styled.div`
  background-color: white;
`

export const CompareMokkula = ({ data }) => {
  const initialState = ""

  const [mokkulas, setMokkulas] = useState(data)
  const [step, setStep] = useState("start")
  const [need, setNeed] = useState(initialState)
  const [speed, setSpeed] = useState(initialState)

  const setFinalStep = () => setStep("final")

  const addNeed = need => {
    setNeed(need)
    setFinalStep()
    if (!process.env.GATSBY_MY_ENVIRONMENT)
      typeof window !== "undefined" &&
        window.gtag("event", `App: Mokkulan käyttötarkoitus`, {
          event_label: need,
        })
  }

  const addStep = step => {
    const correctStep = step === "molemmat" ? "mokkula" : step
    setStep(correctStep)
    if (!process.env.GATSBY_MY_ENVIRONMENT)
      typeof window !== "undefined" &&
        window.gtag("event", `App: Mokkula vai netti valinta`, {
          event_label: step,
        })
  }

  return (
    <Container>
      {step === "start" && (
        <>
          <Heading1>Löydä sopiva mokkula tai nettitikku juuri sinulle</Heading1>
          <Heading2>Hei, mitä tarvitset?</Heading2>
          <ButtonContainer>
            <Button onClick={() => addStep("mokkula")}>Mokkulan</Button>
            <Button onClick={() => addStep("final")}>
              Nettiliittymän mokkulaan
            </Button>
            <Button onClick={() => addStep("molemmat")}>Molemmat</Button>
          </ButtonContainer>
          <Operators />
        </>
      )}
      {step === "mokkula" && (
        <>
          <Heading1>Melkein valmista</Heading1>
          <Heading2>Mihin tarvitset mokkulaa?</Heading2>
          <ButtonContainer>
            <Button onClick={() => addNeed("kotiin")}>Kotiin</Button>
            <Button onClick={() => addNeed("matkalle")}>Matkalle</Button>
            <Button onClick={() => addNeed("ulos")}>Ulos</Button>
          </ButtonContainer>
          <Operators />
        </>
      )}
      {step === "final" && (
        <>
          <Heading1>Valmista tuli</Heading1>
          <ResultContainer>
            {need && <Heading2>Hienoa, valitse nyt sopivin mokkula</Heading2>}
            {need &&
              mokkulas
                .filter(m => m.where.includes(need))
                .map(m => {
                  return (
                    <MokkulaCard
                      place="App"
                      img={m.img}
                      name={m.name}
                      tec={m.tec}
                      onePayment={m.onePayment}
                      price={m.price}
                      time={m.time}
                      features={m.features}
                      url={m.url}
                      pageName="mokkula-taulukko"
                    />
                  )
                })}
            <Heading2>
              {need
                ? "Valitse myös sopiva nettiliittymä mokkulaan"
                : "Valitse sopivin nettiliittymä mokkulaan"}
            </Heading2>
            <MokkulaPlans place="App" pageName="mokkula-taulukko" />
          </ResultContainer>
          <Operators />
        </>
      )}
    </Container>
  )
}
