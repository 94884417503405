import React from "react"
import styled from "styled-components"
import { device } from "../../utils/mediaQueries"
import OperatorImage from "../images/OperatorImage"
import { createRedirectUrl } from "../../utils/redirectHelpers/modifyUrlHelpers"

const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  border: 1px solid lightgray;
  padding: 25px;
  text-align: center;
  align-items: flex-start;
  grid-gap: 20px;
  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 25px 10px;
  }
`

const Mokkula = styled.div`
  display: block;
  min-width: 300px;
`

const Link = styled.a`
  text-decoration: none;
`

const OrderButton = styled.button`
  padding: 1rem 3rem;
  background-color: #004e91;
  border: none;
  border-radius: 15px;
  outline: none;
  color: white;
  font-size: 17px;
  text-align: center;
  font-weight: 500;
  margin-top: 20%;
  cursor: pointer;
  :hover {
    background-color: #f16603;
  }
`

const CardItem = styled.div`
  display: grid;
  grid-gap: 10px;
`

const BoldFont = styled.div`
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
`

const LightFont = styled.div`
  font-size: 15px;
  font-weight: 200;
  color: #565656;
  text-transform: lowercase;
`

const OpenFeeText = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #565656;
`

const PriceTag = styled.span`
  font-size: 14px;
  text-transform: lowercase;
  font-weight: 200;
`

const MokkulaName = styled.div`
  margin: 0px 20px;
  font-size: 0.8rem;
  font-weight: 800;
`

const TecText = styled.h5`
  color: white;
  background-color: #a35dbc;
  padding: 1rem;
  border-radius: 100%;
  font-size: 1rem;
`

const InterestText = styled.p`
  font-size: 0.7rem;
  font-weight: 800;
  color: blue;
  margin: 0.2rem;
  padding: 0.1rem;
`

const FeaturesContainer = styled.div`
  display: block;
  text-align: left;
  @media ${device.tablet} {
    text-align: center;
  }
`

const Feature = styled.p`
  margin: 0.3rem 0.1rem;
  padding: 0.1rem;
  @media ${device.tablet} {
    margin: 0.6rem 0.1rem;
  }
`

const MokkulaCard = props => {
  const place = props.place
  const trackClick = place => {
    typeof window !== "undefined" &&
      window.gtag("event", `${place}: Mokkulaa klikattu`, {
        event_label: props.name,
      })
  }
  return (
    <Card>
      <Mokkula>
        <OperatorImage imgName={props.img} />
        <MokkulaName>{props.name}</MokkulaName>
      </Mokkula>
      <CardItem>
        <TecText>{props.tec}</TecText>
      </CardItem>
      <CardItem>
        <BoldFont>
          {props.onePayment} <PriceTag>€</PriceTag>
        </BoldFont>
        <LightFont>
          tai {props.price}€ <PriceTag>/kk</PriceTag>
        </LightFont>
        <InterestText>{props.time}kk 0% korot</InterestText>
      </CardItem>
      <CardItem>
        <FeaturesContainer>
          {props.features.map((f, i) => {
            return <Feature key={i}>{f}</Feature>
          })}
        </FeaturesContainer>
      </CardItem>
      <Link
        href={createRedirectUrl(props.pageName, props.name)}
        target="blank"
        rel="nofollow"
        onClick={() => trackClick(place)}
      >
        <OrderButton>Tutustu</OrderButton>
      </Link>
    </Card>
  )
}

export default MokkulaCard
