import React from "react"
import Container from "../components/layout/textFormatingComponents/Container"
import { graphql, Link } from "gatsby"
// import MokkulaCard from "../components/resultComponents/MokkulaCard"
import GlobalStyles from "../components/layout/GlobalStyles"
import Header1 from "../components/layout/textFormatingComponents/Header1"
import Header2 from "../components/layout/textFormatingComponents/Header2"
import Header3 from "../components/layout/textFormatingComponents/Header3"
import Header4 from "../components/layout/textFormatingComponents/Header4"
import TextArea from "../components/layout/textFormatingComponents/TextArea"
import { MokkulaCompareContainer } from "../components/mokkulaComponents/MokkulaCompareContainer"
import MetaDetails from "../components/seoComponents/MetaDetails"
import styled from "styled-components"
import { device } from "../utils/mediaQueries"
import { FilterComponents } from "../components/mokkulaComponents/FilterComponents"
import { OutboundLink } from "gatsby-plugin-gtag"
import { CompareMokkula } from "../components/compareApps/compareMokkula/CompareMokkula"

const ActionButton = styled(Link)`
  text-decoration: none;
  padding: 1rem 3rem;
  background-color: #a35dbc;
  color: white;
  font-weight: 700;
  word-wrap: break-word;
  text-align: center;
  margin: 0px auto;
  &:hover {
    background-color: violet;
  }
  @media ${device.tablet} {
    padding: 0.7rem;
  }
`

const CenterContext = styled.div`
  text-align: center;
`

const FrontPage = ({ data }) => {
  const plansHook = data.allMokkulatJson.nodes

  return (
    <GlobalStyles>
      <MetaDetails
        title="Valitse paras 4g/5g mokkula tai nettitikku - Katso täältä!"
        desc="Mikä on sopiva mokkulan tai nettitikun hinta ilman liitymää? DNA, Telia vai Elisa/Saunalahti? Katso vastaukset täältä ja valitset varmasti parhaan 4g tai 4g mokkulan/nettikun!"
      />
      <Container>
        {/* <Header1>Millainen mokkula tai nettitikku kannattaa valita?</Header1> */}
        <CompareMokkula data={data.allMokkulatJson.nodes} />
        <Header3>Jatka alapuolelta mokkuloihin tutustumista</Header3>
        <TextArea>
          Mokkula ja nettitikku mahdollistavat internetin helpon ja nopean
          käytön paikoissa, jossa muuten voisi olla vaikeata olla internetin
          päässä. Tekee esimerkiksi työskentelemisestä tai opiskelusta
          mahdollista ihan missä päin tahansa. Me tarjoamme sinulle parhaat
          vinkit ja niksit sopivan nettitikun valintaan.
        </TextArea>
        <CenterContext>
          <FilterComponents />
          <MokkulaCompareContainer plansHook={plansHook} place="Page" />
        </CenterContext>
        <ActionButton to="/mobiililaajakaista">
          Vertaa paras mobiililaajakaista mokkulalle
        </ActionButton>
        <Header3>
          Tutustu parhaimpiin mokkuloihin/nettitikkuihin alapuolelta:
        </Header3>
        {/* {plans} */}
        <Header2>Mokkulan tai nettitikun valinta</Header2>
        <TextArea>
          Jos matkustat työn tai vapaa-ajan puolesta paljon, eikä sinulle riitä
          vain kahviloiden ilmaiset langattomat netit, nettiyhteyden saaminen
          läppäriin voi olla hyvä idea hoitaa mokkulalla tai nettitikulla.
          Tällöin saat netin käyttöön silloin, kun sitä eniten tarvitset.
        </TextArea>
        <TextArea>
          Mokkula toimii hyvin myös esimerkiksi mökillä silloin, jos laitteiden
          omat liittymät eivät riitä, tai jos haluat langattoman verkkoyhteyden
          mökkiympäristöön. Mokkulan tai nettitikun ostamisessa kannattaa
          kuitenkin pitää muutama asia mielessä, sillä ei ole täysin
          yhdentekevää, minkä laitteista valitsee.
        </TextArea>
        <Header3>Miten valita sopiva mokkula tai nettitikku?</Header3>
        <TextArea>
          Ennen kuin mokkulan tai nettitikun valintaan perehdytään tarkemmin, on
          hyvä käydä läpi, mitä nämä laitteet ovat. Nettitikku on sanana ehkä
          hieman helpompi kuvitella ja sisäistää. Käytännössä kyseessä on
          USB-asemaan asetettava tikku, joka yhdistää puhelimen nettiin toimien
          samalla myös antennina. Mokkula sen sijaan on itse asiassa vain
          mainostajien keksimä nimi kyseiselle laitteelle, jonka tehtävänä on
          vastaanottaa mobiililaajakaistaliittymän yhteyttä ja jakaa sitä
          eteenpäin niin, että esimerkiksi läppäri tai tabletti pystyy
          hyödyntämään yhteyttä internetin käyttämiseksi.
        </TextArea>
        <TextArea>
          Mokkulan oikea nimi on mobiililaajakaistareititin, siis lyhyesti
          mobiilimodeemi. Myös nettitikku on reititin, tarkemmin sanottuna
          USB-reititin, joka siis kiinnitetään suoraan läppäriin. Mokkula sen
          sijaan toimii useammin joko langattomalla yhteydellä, tai
          vaihtoehtoisesti kappaleen voi myös kiinnittää USB- tai
          ethernet-porttiin. Monelle voi olla epäselvää, miten mokkulan
          nettiyhteys toimii. Mobiilireitittimien netti toimii ihan samalla
          tavalla kuin älypuhelimien netti, eli mobiililaajakaistaliittymän
          avulla. Mobiililaajakaista onkin yleisnimitys kaikille liikkuville
          nettiyhteyksille, jotka toimivat käyttäjiensä päätelaitteissa
          pääasiassa SIM-korttien avulla.
        </TextArea>
        <TextArea>
          Mobiililaajakaistaliittymiä tarjoavat Suomessa
          mobiiliverkko-operaattorit, joilla on tähän tarpeeseen kehitetty
          matkapuhelinverkko ja siihen liittyvä toimilupa viranomaisilta.
          Suomalaisia mobiiliverkko-operaattoreita ovat kaikkien suomalaisten
          tuntemat DNA, Elisa ja Telia. Kaikki nämä yritykset tarjoavat myös
          erillisiä liittymäratkaisuja mobiililaitteisiin matkapuhelinten
          lisäksi. Tablettien ja älypuhelinten erona normaalisti on, että
          tabletteihin ei ole tapana liittää omaa SIM-korttia. Tämän vuoksi
          tabletteja käytetään enimmäkseen Wi-Fi-yhteyden kiinteästä
          laajakaistasta. Mobiiliverkkomaailmassa puhutaan usein{" "}
          <a href="https://fi.wikipedia.org/wiki/4G">4G:stä</a> ja{" "}
          <a href="https://fi.wikipedia.org/wiki/5G">5G:stä</a>. Alla on
          selvennettynä, mitä näillä G-verkoilla tarkoitetaan:
          <ul>
            <li>
              Neljännen sukupolven mobiililaajakaistaa kutsutaan 4G-verkoksi
            </li>
            <li>
              Sukupolvet eli G:t ovat itse asiassa sidoksissa verkon nopeuteen
              ja suorituskykyyn
            </li>
            <li>
              Seuraavan sukupolven verkko, 5G, kaupallistuu muutaman vuoden
              sisään
            </li>
            <li>
              5G käyttää 4G:tä enemmän dataa, mutta sen käyttö on myös
              joustavampaa erilaisissa digitaalisissa ympäristöissä
            </li>
          </ul>
        </TextArea>
        <TextArea>
          Kannettavia tietokoneita, joihin saa mobiililaajakaistan suoraan
          omalla SIM-kortilla, löytyy markkinoilta esimerkiksi Googlen ja HP:n
          valmistamina. Jos läppäriin haluaa suoraan kytkeä oman SIM-kortin,
          mutta tehdasmalli ei tue sitä, tulee laitteeseen asentaa oma portti
          SIM-kortille. Jos siis haluat liittää läppärin tai tabletin helposti
          nettiin käyttämättä puhelimen nettiä, tai toisaalta jos tarkoituksena
          on liittää useampi laite samaan aikaan nettiin ilman puhelinkäytön
          häiriintymistä, mobiililaajakaistareititin, eli mokkula tai
          nettitikku, on luultavasti paras vaihtoehto liikkuvaan nettiin.
        </TextArea>
        <Header3>
          Mitä kannattaa ottaa huomioon mokkulaa tai nettitikkua valittaessa?
        </Header3>
        <TextArea>
          Mokkulan valinnassa ensisijaisesti kannattaa valita sellainen laite,
          joka tukee omaa liittymää, sillä yhtä tärkeää laitevalinnan kanssa on
          valita mobiililaajakaistan toimittaja. Vaikka mokkuloita ja
          nettitikkuja on parhaiten saatavilla juuri operaattorien valikoimista,
          niitä saa ostettua erikseen myös erikseen muun muassa
          Verkkokauppa.comista, Gigantista ja Powerilta. Vaikka et siis tilaisi
          omaa mokkulaa jo käytössä olevalta matkapuhelinoperaattoriltasi, tällä
          ei ole merkitystä. Mokkularatkaisut eivät ole sidoksissa
          operaattoreihin, vaikka operaattoreiden omasta mainonnasta ja
          lehtitarjouksista näin voisikin päätellä.
        </TextArea>
        <TextArea>
          Kaikkien verkko-operaattorien SIM-kortit ja verkot toimivat kaikissa
          reitittimissä, mikä on kuluttajan kannalta tietysti hyvä juttu.
          Erilaisia mokkula- ja nettitikkuratkaisuja tarjoavat muun muassa
          TP-Link, Netgear, ZTE ja Zyxel, ja etenkin viime vuosina
          mokkulamalleista on tullut uusia, todella varteen otettavia
          vaihtoehtoja tavalliselle kiinteälle netille tai WiFi-verkoille.
          Tärkein osa monien ostopäätöksessä on hinta, joka voi vaihdella
          huomattavasti. Erityisesti mokkulat voivat olla aika hintavia erikseen
          ostettuna. Mokkuloita on paljon eri hintaluokissa, tavallisista
          taskumalleista aina aikamoisiin pöytärakennelmiin
        </TextArea>
        <TextArea>
          Myös keskihintaisia mobiilireitittimiä on markkinoilla onneksi
          suhteellisen paljon. Mokkuloiden ja nettitikkujen hinta liikkuu noin
          viidestäkymmenestä eurosta ylöspäin. Esimerkiksi TP-Linkillä on
          edullisiakin mokkuloita valikoimissaan, joskin nämä noin korttipakan
          kokoiset pikkumodeemit on tarkoitettu lähinnä yhdelle tai kahdelle
          laitteelle, ja liitettäväksi suoraan päätelaitteen USB-porttiin.
          Toisessa ääripäässä ovat lähes kiinteämalliset mokkulat, jotka eivät
          ulkonäöltään tai toiminnallisuuksiltaan eroa juuri ollenkaan
          tavallisista laajakaistamodeemeista. Kiinteään laajakaistaan kannattaa
          toki panostaa jos on mahdollista, mutta aina näin ei ole.
        </TextArea>
        <TextArea>
          Toinen tärkeä asia on, kuinka paljon verkkoa tulee käytettyä ja mihin
          tarkoitukseen, sillä mokkulan tai nettitikun nettiyhteyteen vaikuttaa
          laitteen ohella liittymä. Jos mobiilireitittimellä muodostettu yhteys
          on korkeimmillaan esimerkiksi 20 megatavua, voit ostaa lähes minkä
          tahansa mokkulan tähän tarpeeseen. Toisaalta taas jos mokkulalle
          ostettu mobiililaajakaista on vaikkapa yli 100 megatavun netti,
          kannattaa myös laitteeseen panostaa hieman enemmän. Tällöin saat
          mokkulasi nettiyhteydestä eniten irti ilman, että yhteys kärsisi
          kehnosta laitteesta.
        </TextArea>
        <Header3>Näin saat parhaan nettiyhteyden mokkulalla</Header3>
        <TextArea>
          Netin käyttötavat myös poikkeavat ihmisten välillä todella paljon.
          Onko mokkulan tarkoitus tulla työkäyttöön niin, että käytät sitä
          jatkuvasti, tai katsotko mobiilireitittimen välityksellä videoita?
          Tavalliseen nettiselaamiseen, uutisten lukemiseen ja Facebookin
          käyttöön ei tarvita laajaa kaistanleveyttä. Jos kuitenkin mokkulaa
          käytetään katvealueilla kesämökillä tai ruuhkaseuduilla, joissa
          mobiiliverkkojen tukiasemat käsittelevät todella paljon
          tiedonsiirtoja, laajakaistainen verkko auttaa. Silti tulee muistaa,
          että mokkulan kautta siirrettävän yhteyden nopeuttaa laitteen niin
          kutsuttu maksimikapasiteetti, joka tarkoittaa sitä
          enimmäistiedonsiirtonopeutta, joka mokkulan avulla on mahdollista
          saavuttaa.
        </TextArea>
        <TextArea>
          Maksimikapasiteetti liittyy siis mokkulan omaan suorituskykyyn
          riippumatta siitä, minkälainen operaattorin verkko on käyttöalueella.
          On myös ulkoisia asioita, jotka voivat vaikuttaa suoraan mokkulan
          netin nopeuteen; niitä ovat esimerkiksi rakennukset, säätila ja
          puusto. Tärkeä on pohtia myös sitä, kuinka suuri alue halutaan kattaa
          mokkulan netillä. Kesämökillä yksinkertainen mokkula toimii
          moitteettomasti yhdessä tai kahdessa huoneessa, mutta jos haluat, että
          netti toimii myös vaikkapa pihalla tai puutarhassa, tarvitset
          paremmalla kantoalueella varustetun mokkulan.
        </TextArea>
        <TextArea>
          Myös ihan mokkulan sijaintikin voi ratkaista pahimmat yhteysongelmat,
          ja parhaan yhteyden mokkulasta ja muistakin modeemeista saa irti
          silloin kun ne on sijoitettu huoneen kattoon. Sen sijaan huoneen
          nurkka tai lattiataso ei ole erityisen hyvä sijainti mokkulalle, jos
          haluaa että signaali toimii oikein. Yksi hyvä idea on sijoittaa
          mokkula talon sisälle, pihan puoleisen ikkunan yläpuolelle. Näin
          mokkulan nettiä pystyy käyttämään kesäaikaan sekä ulkona että sisällä.
          Ikkuna estää yhteyttä vähemmän kuin tavallinen seinä.
        </TextArea>
        <TextArea>
          Syrjäseudulla mobiilireitittimeen kannattaa hankkia myös antenni.
          Mokkulaan asennettava antenni on usein erillinen kapistus, jolla saa
          tavallisen kiinteämallisen mokkulan signaalia vahvistettua
          huomattavasti. Antenni asennetaan talon ulkoseinään tai katolle kuten
          mikä tahansa muukin antenni, ja liitetään mokkulaan.
          Antennimokkuloiden, eli ulkomokkuloiden, hinnat ovat tavallisia
          mokkuloita noin kaksi kertaa kalliimpia, eli antennimokkulan hinta
          pyörii noin 200-300 euron paikkeilla. Antenni on oiva lisä mokkulaan
          silloin, jos tuntuu, että talon sijainti estää kunnon yhteyden
          luomisen.
        </TextArea>
        <Header4>Minkälainen mokkula on hyvä?</Header4>
        <TextArea>
          Mokkulan käytön, nopeuden ja toimintasäteen osalta olennaista on myös
          se, kuinka monta laitetta mokkulan nettiin on ajateltu yhdistää. Jos
          tarkoituksena on, että mokkulan nettiä voi käyttää koko perhe,
          tarvitsee mokkulan suorituskykyyn kiinnittää enemmän huomiota, samoin
          netin nopeuteen. Vaikka edullisemmassakin reitittimessä, kuten
          esimerkiksi TP-Linkin kannettavissa malleissa, on usein maininta 10
          eri laitteen tuesta samanaikaisesti, voi reititintä kymmenellä
          laitteella käytettäessä tulla tenkkapoo, tai ainakin akun lataus
          toimii vain muutamia tunteja.
        </TextArea>
        <TextArea>
          Suurin osa Suomessa myytävistä mokkuloista on nykyään kiinalaisen
          laite-, ja verkkovalmistaja Huawein mokkuloita. Suomessa suosittuja
          myytyjä mokkuloita ovat muun muassa{" "}
          <OutboundLink href="/tilaa/elisakotimokkula4g" rel="nofollow">
            Huawei B715
          </OutboundLink>
          , mokkula, johon on mahdollista saada myös ulkoinen antenni. TP-Linkin
          Archer-mallin modeemit ovat myös hyvin kätevä valinta, sillä niissä on
          yhdistettynä sekä langattoman että langallisen laajakaistamodeemin
          parhaat puolet, joten ne toimivat sekä suoraan verkkoliitännästä että
          kaikkien operaattorien{" "}
          <a href="/blogi/sim-korttien-koot/">SIM-korteilla</a>, ja maksavat
          noin sata euroa. Ota huomioon seuraavat asiat mokkulan SIM-kortin
          valinnassa:
          <ul>
            <li>Mokkuloissa käytetään tavallisen kokoisia SIM-kortteja</li>
            <li>
              Älypuhelimen mikroSIM-kortit eivät normaalisti kelpaa mokkulaan
            </li>
            <li>
              Operaattorien liikkeistä saa adaptereita SIM-korttien koon
              vaihtamista varten
            </li>
            <li>
              Jos liität vanhan liittymän mokkulaan, uusi samalla SIM-kortti
              operaattorilla
            </li>
            <li>
              SIM-kortti asennetaan mokkulan kylkeen samalla tavalla kuin
              älypuhelimeen
            </li>
          </ul>
        </TextArea>
        <TextArea>
          Kun puhutaan ammattilaiskäyttöön tarkoitetuista mokkuloista, joita
          myydään myös kuluttajille, näitä valmistaa muiden mokkulavalmistajien
          lisäksi myös esimerkiksi Asus. Ammattilaiskäyttöön sopivat
          mobiilireitittimet ovat yleensä ennen kaikkea parempia akunkestoltaan
          ja potentiaaliselta netin nopeudelta, ja toimivat samalla tavalla
          TP-Linkin Archer-malliston kanssa sisältäen mahdollisuuden myös
          langallisen verkon jakamiseen. Näistä mokkulamalleista esimerkiksi
          Asuksen AG-AC53U Dual-Band LTE-modeemi yhdistettynä WiFi-tukiasemaan
          maksaa noin 180 euroa, ja tällä mokkulan ja modeemin yhdistelmällä
          4G-mobiililaajakaista toimii jopa 300 megan nopeudella.
        </TextArea>
        <TextArea>
          Korkeimman hintaluokan ja parhaan suorituskyvyn mobiilireitittimiä
          edustaa Netgearin MR2100 3G/4G/LTE -mobiilireititin, joka toimii
          hyvällä mobiililaajakaistayhteydellä luultavasti tehokkaammin kuin
          suurin osa Suomessa myytävistä kiinteistä laajakaistaverkoista. Tämä
          4G-tuettu laite mahdollistaa jopa 20 eri käyttäjän samanaikaisen
          nettiyhteyden ja mobiililaajakaistan latausnopeudeksi jopa gigatavun
          sekunnissa. Tällä mokkulalla on hintaa noin 600 euroa. Edullisempi
          Netgearin gigatavun latausnopeutta tukeva MR1100, maksaa hieman alta
          300 euroa, joten se on hyvä valinta hyvinkin raskaaseen netin käyttöön
          kaikissa olosuhteissa.
        </TextArea>
        <Header3>Mokkulalle tai nettitikulle sopivat liittymät</Header3>
        <TextArea>
          Mokkulalle tarvitsee laitteen lisäksi hankkia netti. Valitettavasti
          siis nämäkään kapistukset eivät saa luotua nettiyhteyttä tyhjästä,
          vaikka joillain operaattoriasiakkailla saattaa edelleen se
          harhakäsitys olla. Mobiililaajakaistaliittymät, jotka toimivat
          mokkuloissa, ovat lähtökohtaisesti samoja kuin älypuhelimiin saatavat
          liittymät, mutta markkinointi-ihmiset vain ovat brändänneet kyseiset
          liittymät eri tavalla. <a href="/dna">DNA:lla</a> kiinteähintaiset
          liittymät ovat tuotenimellä{" "}
          <OutboundLink rel="nofollow" href="/tilaa/dnamatkanetti4gmax">
            Matkanetti 4G Max (44,90 €/kk)
          </OutboundLink>{" "}
          ja{" "}
          <OutboundLink rel="nofollow" href="/tilaa/dnamatkanetti4gsuper">
            Matkanetti 4G Super (29,90 €/kk)
          </OutboundLink>
          , joista Max-liittymä on maksiminopeudellinen mobiililaajakaista, ja
          Super-liittymä hieman heikompi.
        </TextArea>
        <TextArea>
          <a rel="nofollow" href="/telia">
            Telialla
          </a>{" "}
          mokkuloille tarkoitettuja mobiililaajakaistayhteyksiä ovat
          Rajaton-liittymäpaketin liittymät, joista edullisin, 0,256 megatavun
          liittymä, maksaa 7,90 euroa kuukaudessa. Tästä seuraava liittymä onkin
          100 megatavun liittymä, jonka hinta on 24,90 euroa kuukaudessa. Onkin
          erikoista, miksi Telialta ei ole mahdollista saada esimerkiksi 20
          megatavun mobiililiittymää mokkuloita varten. Elisalla sen sijaan on
          Saunalahti-liittymissään mahdollista tilata mobiililaajakaista
          kahdella eri hinnoittelumallilla. Erityisesti tablettikäyttöön
          suunniteltu Mobiililaajakaista Tablet 4G tarjoaa rajattoman netin 2,90
          euron kuukausihinnalla. Kuukausihinnan päälle jokaiselta
          käyttöpäivältä veloitetaan 0,99 euroa.
        </TextArea>
        <TextArea>
          Päiväveloitteisen mobiililaajakaistan lisäksi{" "}
          <a rel="nofollow" href="/elisa">
            Saunalahdella
          </a>{" "}
          on valikoimissaan{" "}
          <a href="/mobiililaajakaista/">
            mobiililaajakaistaliittymiä 100 megan yhteydestä ylöspäin aina
            tuhanteen megaan asti
          </a>
          , joka lienee oletusarvoinen maksiminopeus 4G-verkossa. Saunalahden
          liittymien hinta vaihtelee 18,40 euron ja 44,90 euron välillä. Kolmen
          ison operaattorin lisäksi mobiililaajakaistaliittymiä tarjoaa myös
          operaattori{" "}
          <a rel="nofollow" href="/moi">
            Moi Mobiili
          </a>
          , jonka{" "}
          <a href="/tilaa/moirajattomanreilu" rel="nofollow">
            100 megatavun rajaton nettiliittymä
          </a>{" "}
          maksaa 18 euroa kuukaudessa, jonka lisäksi paljon matkustavalle
          tarjolla on 2 gigaa ilmaista EU-alueen roaming-nettikäyttöä. Jos siis
          haluat taloudellisen 100 megatavun netin mokkulaan, edullisin
          vaihtoehto on{" "}
          <a href="/tilaa/moiperus" rel="nofollow">
            Moi Mobiili.
          </a>
        </TextArea>
        <TextArea>
          Koska liittymät ovat samoja liittymiä sekä älypuhelimille että
          mokkuloille, voi tulla mieleen se, voiko puhelinta käyttää mokkulana?
          Useimpia nykyisiä älypuhelinmalleja on mahdollista käyttää
          mobiilireitittimenä joko langattomasti tai USB-portin kautta.
          Miinuspuolia puhelimen mokkulakäytössä ovat etenkin akun kesto, sillä
          netin jakaminen puhelimella syö tolkuttomasti akkua, etenkin jos
          älypuhelinta on tarkoitus käyttää mobiiliyhteyden jakamisen lisäksi.
          Mobiiliyhteyden osalta puolikiinteä antennimokkula lyö tavallisen
          älypuhelimen mennen tullen, joten esimerkiksi mökille paras hankinta
          on oma mokkula.
        </TextArea>
        <Header2>Yhteenveto</Header2>
        <TextArea>
          Mokkuloita ja nettitikkuja on monenlaisia, ja mitä ketterämmäksi
          mobiililaajakaista tulee, sitä parempi vaihtoehto se on myös ihan
          tavalliselle kiinteälle laajakaistalle. Mobiilireititin on hyvä
          valinta etenkin matkoille ja kesäasunnolle.
        </TextArea>
        <TextArea>
          Jos haluat liittää läppärin tai tabletin helposti nettiin käyttämättä
          puhelimen nettiä, tai toisaalta jos tarkoituksena on liittää useampi
          laite samaan aikaan nettiin ilman puhelinkäytön häiriintymistä,
          mobiililaajakaistareititin on luultavasti paras vaihtoehto liikkuvaan
          nettiin.
        </TextArea>
      </Container>
    </GlobalStyles>
  )
}

export const query = graphql`
  query {
    allMokkulatJson {
      nodes {
        desc
        id
        img
        name
        onePayment
        tec
        speed
        features
        price
        title
        time
        url
        where
      }
    }
  }
`

export default FrontPage
