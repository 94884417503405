import React from "react"
import styled from "styled-components"
import MokkulaCard from "./MokkulaCard"
import { MokkulaPlans } from "./MokkulaPlans"
import { device } from "../../utils/mediaQueries"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const ShowPlansText = styled.div`
  margin: 0px;
  padding: 1rem;
  font-weight: 800;
  background-color: #6a088c;
  color: white;
  margin-bottom: 1rem;
`

const Heading = styled.h3`
  color: #6a088c;
  font-weight: 800;
  margin: 2rem;
  font-size: 1.5rem;
`

const CardContainer = styled.div`
  margin: 4rem;
  @media ${device.tablet} {
    margin: 2rem 0.1rem;
  }
`

export const MokkulaCompareContainer = props => {
  const plans = props.plansHook.map(plan => {
    const fourG = plan.tec === "4G"
    return (
      <CardContainer>
        <a id={plan.where}></a>
        <Heading>{plan.title}</Heading>
        <MokkulaCard
          place={props.place}
          key={plan.id}
          img={plan.img}
          name={plan.name}
          price={plan.price}
          onePayment={plan.onePayment}
          url={plan.url}
          desc={plan.desc}
          where={plan.where}
          time={plan.time}
          tec={plan.tec}
          features={plan.features}
          pageName={"mokkula-taulukko"}
        />
        <ShowPlansText>
          Katso parhaat tähän mokkulaan sopivat mobiililaajakaistat alapuolelta
        </ShowPlansText>
        <MokkulaPlans
          fourg={fourG}
          place={props.place}
          pageName="mokkula-taulukko"
        />
      </CardContainer>
    )
  })

  return <Container>{plans}</Container>
}
